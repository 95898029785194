import React from "react"
import SocialMenu from "./SocialMenu"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const FooterMenusWidgets = ({}) => {
  return (
    <div className="footer-nav-widgets-wrapper header-footer-group">
      <div className="footer-inner section-inner">
        <div className="footer-top has-social-menu">
          <SocialMenu />
        </div>

        <aside className="footer-widgets-outer-wrapper" role="complementary">
          <div className="footer-widgets-wrapper">
            <div className="footer-widgets column-one grid-item">
              <div className="widget widget_text">
                <div className="widget-content">
                  <h2 className="widget-title subheading heading-size-3">
                    TeknoAsist Hakkında
                  </h2>
                  <div className="textwidget">
                    <p>
                      10+ yıllık tecrübeli, alanında uzman yazar ve editör ekibiyle birlikte 
                      internet dünyasına ait ne nasıl yapılır, 
                      hangisini neden tercih etmeliyim gibi soruları yanıtlamaya çalışıyoruz. 
                    </p>
                    <p>
                      Sorularınızı ve yorumlarınızı yazılarımızın altına ekleyebilirsiniz. 
                      Elimizden geldiğince cevaplamaya çalışacağız.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="footer-widgets column-two grid-item">
              <div className="widget widget_text">
                <div className="widget-content">
                  <div className="textwidget">
                    <p>
                      <br />
                      <br />
                      <Link to={"/kunye"}>Künye</Link><br />
                      <Link to={"/gizlilik-politikasi"}>Gizlilik Politikası</Link><br />
                      <Link to={"/alintilama-hakkinda"}>Alıntılama Hakkında</Link><br />
                      <Link to={"/iletisim"}>İletişim</Link>
                    </p>
                  </div>
                  <br />
                  <StaticImage src="../assets/images/ta-64.png" alt="teknoasist.com" placeholder="blurred" width={64} height={64}/>
                </div>
              </div>
            </div>
          </div>
        </aside>
      </div>
    </div>
  )
}

export default FooterMenusWidgets
